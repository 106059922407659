































/* eslint-disable @typescript-eslint/no-var-requires */
import '@/assets/styles/participant.scss';
import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'SurveyLayout',
  computed: {
    ...mapGetters('participant', [
      'survey',
      'clientLogo',
      'surveyDesign',
      'template',
    ]),
  },
})
export default class SurveyLayout extends Vue {
  readonly survey!: any;
  readonly clientLogo!: any;
  readonly surveyDesign!: any;
  readonly template!: any;

  // GETTERS
  get surveyLogo() {
    // TODO: refactor this??
    if (
      this.template &&
      this.template.name.indexOf('Culture Health Check') > -1
    ) {
      return this.clientLogo
        ? require('@/assets/images/logo-chc-colour.png')
        : require('@/assets/images/logo-chc.png');
    }
    return this.clientLogo
      ? require('@/assets/images/logo-culture-diagnostics-colour.png')
      : require('@/assets/images/logo-culture-diagnostics.png');
  }

  get headerStyle() {
    const headerStyle: any = {};
    if (this.surveyDesign) {
      if (this.clientLogo) {
        headerStyle.backgroundColor = 'white';
      }
      if (this.surveyDesign.lineColor) {
        headerStyle.borderBottomColor = this.surveyDesign.lineColor;
      }
    }
    return headerStyle;
  }

  get background() {
    if (this.$route.params.surveySlug) {
      if (this.surveyDesign) {
        // document.body.classList.add('custom');
        if (this.surveyDesign.backgroundColor) {
          document.body.style.background = this.surveyDesign.backgroundColor;
          document.body.style.backgroundImage = 'none';
        } else {
          const image = require('../assets/images/welcome-background.jpg');
          document.body.style.backgroundImage = `url('${image}')`;
        }
        // return this.surveyDesign.backgroundColor
      } else {
        const image = require('../assets/images/welcome-background.jpg');
        document.body.style.backgroundImage = `url('${image}')`;
        // background: -moz-linear-gradient(top, #000 0%, #000 30%, #546572 100%);
        // background: -webkit-linear-gradient(top, #000 0%,#000 30%,#546572 100%);
        // document.body.style.background = 'linear-gradient(to bottom, #000 0%,#000 30%,#546572 100%)';
        // document.body.style.backgroundColor = '#eb01a5';
        // document.body.style.backgroundImage = 'url("/assets/images/welcome-background.jpg")'
        // document.body.style.backgroundImage = 'url("/assets/images/welcome-background.jpg"), linear-gradient(#eb01a5, #d13531)';
        // document.body.style.backgroundRepeat = 'no-repeat';
      }
    } else {
      const image = require('../assets/images/welcome-background.jpg');
      document.body.style.backgroundImage = `url('${image}')`;
    }

    return {};

    // document.body.classList.remove('custom');
    // return `url('/assets/images/welcome-background.jpg') no-repeat center center fixed`;
  }
}
